import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXProvider } from "@mdx-js/react";
import { Link } from "gatsby";
import Kiji from "@components/kiji-link";
import KijiLink from "@components/kiji-link-only";
import Youtube from "@components/youtube";
import YoutubeList from "@components/youtube-list";
import SEO from '@components/seo';
import lazysizes from 'lazysizes';
import '@layouts/global.css';
import Header from '@components/header';
import Footer from '@components/footer';
import H2Back from '@components/h2-back';
import Naiyou from "@components/md-components/naiyou";
import Touroku from "@components/touroku";
export const _frontmatter = {
  "syurui": "tokusyu",
  "key": "merumaga",
  "path": "/merumaga/",
  "title": "E.L.V.Nミックス メールマガジン",
  "description": "８年かかってもMIXできなかった僕が、４時間で、わずかインサート１つでMIXできるようになった話を、詳しく書いています。",
  "date": "2019-01-01",
  "juni": null
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <MDXProvider components={{
      h2: props => <h2>{props.children}<H2Back mdxType="H2Back" /></h2>
    }} mdxType="MDXProvider">
      <SEO title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="SEO" />
      <Header mdxType="Header" />
      <main className="b-center honbun seigen">
        <h1>E.L.V.Nミックス<br />メールマガジン</h1>
        <div className='md'>
          <p>{`何をやってもMIXのできなかった僕が、`}<br parentName="p"></br>{`
`}{`驚異的な効率でMIXできるようになるまでに`}<br parentName="p"></br>{`
`}{`やったことは`}</p>
          <p>{`「`}<strong parentName="p">{`頭の中を整理する`}</strong>{`」`}</p>
          <p>{`ということだけでした。`}</p>
          <p>{`その結果、わずか４時間で`}<br parentName="p"></br>{`
`}{`プロと遜色のないMIXが`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
              "className": "e_wine"
            }}>{`完全に思い通りに`}</strong>{`できるようになりました。`}</p>
          <p>{`今では、そのおかげで`}<br parentName="p"></br>{`
`}{`音楽を仕事にして、`}<br parentName="p"></br>{`
`}{`毎日自由に、クリエイティブな生活を`}<br parentName="p"></br>{`
`}{`満喫しています。`}<br parentName="p"></br>{`
`}{`MIXを教えた方からも、`}<br parentName="p"></br>{`
`}{`とても多くの感動の声をもらっています。`}<br parentName="p"></br>{`
`}{`（下の動画から、教えた方のMIXを聞けます）`}</p>
          <YoutubeList bangou='PLXr-BrRKzpf7bWfHQBxeHEWuPZxLTDVZ9' tuika_class='' mdxType="YoutubeList" />
          <p>{`しかし、こうなるまでに`}<br parentName="p"></br>{`
`}{`僕が見つけた事は`}<br parentName="p"></br>{`
`}{`膨大でした。`}<br parentName="p"></br>{`
`}{`MIX以前に、`}<br parentName="p"></br>{`
`}{`知っておかなければならない知識も`}<br parentName="p"></br>{`
`}{`たくさんありました。`}<br parentName="p"></br>{`
`}{`そこで、それらを適切な量に小分けにして、`}<br parentName="p"></br>{`
`}{`順を追ってわかりやすく伝えられるよう`}<br parentName="p"></br>{`
`}{`無料のメールマガジンを発行することにしました。`}</p>
          <p>{`内容は、`}<br parentName="p"></br>{`
`}<strong parentName="p">{`知らないと絶対にドツボにハマること`}</strong>{`、`}<br parentName="p"></br>{`
`}<strong parentName="p">{`MIX「できない」ように仕向ける業界の闇`}</strong>{`、`}<br parentName="p"></br>{`
`}<strong parentName="p">{`「ぶっちゃけ」MIXのお金の話`}</strong><br parentName="p"></br>{`
`}{`などを含め、多岐に渡ります。`}</p>
          <p>{`具体的には、`}</p>
          <div className='ekisyou t-center t-bold'>
            <Naiyou mdxType="Naiyou" />
          </div>
          <p>{`などです。`}<br parentName="p"></br>{`
`}{`僕のミックスのやり方を学ぶ機会もあります。`}</p>
          <p>{`今のところ、集中して届くのは`}<br parentName="p"></br>{`
`}{`最初の１週間ぐらいで、`}<br parentName="p"></br>{`
`}{`その後は不定期です。`}<br parentName="p"></br>{`
`}{`興味のある方は、下から読めます。`}</p>
          <p>{`無料ですので、いつやめても構いません。`}<br parentName="p"></br>{`
`}{`もちろん、個人情報を悪用したり`}<br parentName="p"></br>{`
`}{`広告やスパムが飛んでくることは`}<br parentName="p"></br>{`
`}{`絶対にありません。`}</p>
          <p><strong parentName="p">{`どうせやるなら賢く。`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
              "className": "e_wine"
            }}>{`スマートに行きましょう。`}</strong></p>
          <p>{`E.L.V.N　ユーキ`}</p>
          <Touroku mdxType="Touroku" />
        </div>
      </main>
      <Footer mdxType="Footer" />
    </MDXProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      